import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { IconButton, Input, InputGroup } from 'rsuite';
import { Icon } from '@iconify/react';
import ConfirmModal from '@components/UI/ConfirmModal';
import OrdersDrawer from '@components/UI/OrdersDrawer';
import UserStore from "@stores/user-store";
import FetchDecorator from '@decorators/FetchDecorator';
import { fetchOrderNumber, fetchOrderRows, postOrderAssembly, postOrderStartAssembly, postRowAssembly } from '@services/Order';
import { OrderDataRow } from '@type/Order';

import '@styles/order.scss'
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import LoaderPage from '@components/UI/LoaderPage';

function Order() {
    const { isFetching, encoded_token, fetchData, current_order } = UserStore
    const [orderRows, setOrderRows] = useState<OrderDataRow[]>([])
    const [currentOrderNum, setCurrentOrderNum] = useState<number>(0)
    const [currentOrderRow, setCurrentOrderRow] = useState<OrderDataRow>()
    const [orderNumber, setOrderNumber] = useState<string>()
    const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)
    const [isOpenConfirmation, setIsOpenConfirmation] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [assembledAmount, setAssembledAmount] = useState<string>('')
    const [assemblyStarted, setAssemblyStarted] = useState<boolean>(false)
    const { orderID } = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        if (!isFetching) {
            setStartCompletion()
        }
    }, [isFetching])
    useEffect(() => {
        if (assemblyStarted) {
            fetchOrderCode()
            fetchOrderData()
        }
    }, [assemblyStarted])
    useEffect(() => {
    }, [isOpenConfirmation])
    useEffect(() => {
        if (orderRows.length) {
            const selectedOrderRow = orderRows[currentOrderNum];
            setCurrentOrderRow(selectedOrderRow)
            setAssembledAmount(selectedOrderRow.completed != null ? selectedOrderRow.completed.toString() : '')
            window.sessionStorage.setItem("currentOrderNum", currentOrderNum.toString())
        }
    }, [currentOrderNum, currentOrderRow])
    useEffect(() => {
        checkCompletion()
    }, [orderRows.length]);

    const checkCompletion = async () => {
        if (!orderRows.length) return
        const isCompleted = orderRows.every(obj => Object.prototype.hasOwnProperty.call(obj, "status") && obj.status == "complete")
        if (isCompleted) {
            setIsOpenConfirmation(true)
        }
    }

    const setStartCompletion = async () => {
        const result = await postOrderStartAssembly(orderID, encoded_token)
        const data = await fetchData()
        console.log(current_order)
        if (!data) {
            toast.error("Заказ уже находится в сборке", { toastId: "orderInJob" })
            navigate('/')
            return
        }
        if (orderID != data.id) {
            toast.error("Заказ уже находится в сборке", { toastId: "orderInJob" })
            navigate('/')
            return
        }
        if (result == StatusCodes.TOO_MANY_REQUESTS) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            setStartCompletion()
        }
        else {
            setAssemblyStarted(true)
        }
    }

    const fetchOrderCode = async () => {
        const response: string = await fetchOrderNumber(encoded_token, orderID)
        setOrderNumber(response)
    }
    const fetchOrderData = async () => {
        const response: OrderDataRow[] = (await fetchOrderRows(encoded_token, orderID))
        if (!response)
            return
        setOrderRows(response)
        setCurrentOrderRow(response[0])
        const sessionOrderNum = window.sessionStorage.getItem("currentOrderNum")
        if (sessionOrderNum)
            setCurrentOrderNum(parseInt(sessionOrderNum))
    }
    const handleAmountSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault()
        setIsLoading(true)
        const _ = assembledAmount.length ? parseInt(assembledAmount) == 0 ? currentOrderRow.ordered : parseInt(assembledAmount) : currentOrderRow.ordered
        if (!currentOrderRow.order_id || !currentOrderRow.id || !_ || !encoded_token) {
            toast.error('Произошла ошибка при сохранении значения. Обновите страницу')
            return
        }
        const response_code = await postRowAssembly(currentOrderRow.order_id, currentOrderRow.id, _.toString(), encoded_token)
        handleNext()
        if (response_code == 200) {
            const changedOrder = orderRows[currentOrderNum]
            if (!changedOrder) {
                toast.error('Произошла ошибка при сохранении значения. Обновите страницу')
                return
            }
            changedOrder.completed = _
            changedOrder.status = 'complete'
            checkCompletion()
        } else {
            toast.error("Ошибка на сервере, попробуйте ещё раз")
        }
        setIsLoading(false)
    }

    const handleBack = () => {
        setCurrentOrderNum(currentOrderNum - 1)
    }
    const handleNext = () => {
        if (currentOrderNum + 1 < orderRows.length)
            setCurrentOrderNum(currentOrderNum + 1)
    }
    const handleSubmit = async (value: string) => {
        const response = await postOrderAssembly(orderID, value, encoded_token)
        if (response == StatusCodes.OK) {
            await fetchData()
            navigate(`/order/${orderID}/complete`)
            return true
        } else {
            toast.error("Ошибка при окончании сборки, попробуйте ещё раз")
            return false
        }
    }
    const handleCancel = async () => {
        setIsLoading(true)
        const response_code = await postRowAssembly(currentOrderRow.order_id, currentOrderRow.id, '0', encoded_token)
        if (response_code == 200) {
            if (!currentOrderRow) {
                toast.error('Произошла ошибка при сохранении значения. Обновите страницу')
                return
            }
            currentOrderRow.completed = 0
            currentOrderRow.status = 'complete'
            checkCompletion()
        } else {
            toast.error("Ошибка на сервере, попробуйте ещё раз")
        }
        setIsLoading(false)
    }
    const handleSelect = (orderRowDataNum: number) => {
        setCurrentOrderNum(orderRowDataNum)
    }
    if (!assemblyStarted || orderRows.length == 0)
        return (
            <LoaderPage />
        )
    if (assemblyStarted && orderRows.length > 0)
        return (
            <div className='full max-screen flex-column order-container'>
                <OrdersDrawer open={isOpenDrawer} setOpen={setIsOpenDrawer} data={orderRows} select={handleSelect} />
                <ConfirmModal open={isOpenConfirmation} handleClose={() => setIsOpenConfirmation(false)} handleSubmit={handleSubmit} />
                <div className='navigation'>
                    <div />
                    <IconButton icon={<Icon icon="bi:list-ul" width="36" height="36" />} appearance="link" onClick={() => setIsOpenDrawer(true)} style={{ color: 'white' }} />
                </div>
                <div className='info-container'>
                    <div className='text-container'>
                        <h1 style={{ paddingBottom: '1rem' }}>{currentOrderNum + 1} из {orderRows.length}</h1>
                        <h1>
                            {currentOrderRow?.place}
                        </h1>
                        <h2>
                            {orderNumber}
                        </h2>
                        <h1>
                            {currentOrderRow?.code}
                        </h1>
                        <h3>
                            {currentOrderRow?.product_name}
                        </h3>
                    </div>
                    <div className='amount-container'>
                        <div className='amount-text'>
                            <p>Заказано</p>
                            <h1>{currentOrderRow?.ordered}</h1>
                        </div>
                        <div className='amount-text'>
                            <p>Остаток</p>
                            <h1>{currentOrderRow?.remain}</h1>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
                <div className='control-container'>
                    <div className='input-container'>
                        <form onSubmit={handleAmountSubmit} style={{ height: 70 }}>
                            {isLoading
                                ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Icon icon="svg-spinners:ring-resize" width="70" height="70" />
                                    </div>
                                ) : (
                                    <InputGroup style={{ borderRadius: 0 }}>
                                        <InputGroup.Addon>
                                            <IconButton disabled={!currentOrderRow} appearance="link" color="green" icon={
                                                <Icon icon="bi:check-lg" width="32" height="32" />
                                            }
                                                type='submit' />
                                        </InputGroup.Addon>
                                        <Input placeholder='' value={assembledAmount} onChange={(v: string) => { setAssembledAmount(v) }} type='number' min={0} step={1} />
                                        <InputGroup.Addon>
                                            <IconButton disabled={!currentOrderRow} appearance="link" color="red" icon={
                                                <Icon icon="bi:x-lg" width="32" height="32" />
                                            }
                                                onClick={() => { handleCancel(); handleNext() }} />
                                        </InputGroup.Addon>
                                    </InputGroup>
                                )}
                        </form>
                    </div>
                    <div className='paginate-container'>
                        <div>
                            <IconButton disabled={currentOrderNum < 1} appearance='primary' icon={<Icon icon="bi:arrow-left" width="128" height="64" />} onClick={handleBack} style={{ borderRadius: 0 }} />
                        </div>
                        <div>
                            <IconButton disabled={currentOrderNum + 1 >= orderRows.length} appearance='primary' icon={<Icon icon="bi:arrow-right" width="128" height="64" />} onClick={handleNext} style={{ borderRadius: 0 }} />
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default FetchDecorator()(Order)
